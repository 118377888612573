<template>
  <div class="footer-panel">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.footer-panel {
  margin-top: auto;
}
</style>
